import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Loader, Card, Space, Row, Col } from 'ebs-design';
import { company } from 'api';
import { UserContext } from 'contexts';
import { formatDate } from 'libs';
import { DescriptionItem, Layout } from 'components';
import { arrayContainsArray } from 'utils';
import { CustomerRole } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const Contract: React.FC = () => {
  const { id } = useParams();
  const { t } = useIntl();
  const { userRoles } = React.useContext(UserContext);

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', id],
    () => company.get(id),
    {
      enabled: Boolean(id),
    },
  );

  const { data: companyRate, isLoading } = useQuery(
    ['company-report-rates', id],
    () => company.getReportRates(id),
    {
      enabled: !!id,
      select: (data) => data?.[0],
    },
  );

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoadingCompany}>
          {dataCompany && <DebtorProfile data={dataCompany} />}
        </Loader>
        <Card>
          <Card.Header bordered>{t('limits_and_rates')}</Card.Header>

          <Card.Body className="bg-white p-0">
            <Loader loading={isLoading || isLoadingCompany}>
              <Row className="overflow-hidden" g={0}>
                <Col className="pb-20">
                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('status')}
                        value={t(companyRate?.invoice_status?.toLowerCase?.() || '---')}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <DescriptionItem
                          label={t('nr_of_active_invoices')}
                          value={companyRate?.active_invoices}
                        />
                      </Space>
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('date_signed')}
                        value={formatDate?.(dataCompany?.contract?.signed_date) || '---'}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('available_to')}
                        value={formatDate?.(dataCompany?.contract?.expiring_date) || '---'}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('available_limit')}
                        value={dataCompany?.limit?.accessible}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('total_nr_of_invoices')}
                        value={companyRate?.total_invoices}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('total_turnover')}
                        value={companyRate?.total_turnover}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('average_monthly_turnover')}
                        value={companyRate?.average_monthly_turnover}
                      />
                    </Col>
                  </Row>

                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t(`total${!isAdherent ? '_collected' : ''}_commission`)}
                        value={companyRate?.total_commission_paid}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t(`total${!isAdherent ? '_collected' : ''}_penalties`)}
                        value={companyRate?.total_penalties_paid}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('total_warranty_returned')}
                        value={companyRate?.total_refunded_guarantee}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('nr_of_invoices_under_examination')}
                        value={companyRate?.invoices_under_examination}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col style={{ maxWidth: 0 }}>
                  <div className="form-divider vertical" />
                </Col>

                <Col className="p-20" lg={4}>
                  <Space
                    align="start"
                    direction="vertical"
                    justify="space-between"
                    style={{ height: '100%' }}
                  >
                    <DescriptionItem
                      label={t('average_invoice')}
                      value={` ${t('amount')}: ${companyRate?.average_invoice_value || 0} MDL / ${t(
                        'term',
                      )}:
                        ${Math.round?.(Number(companyRate?.average_invoice_term)) || 0} ${t(
                        'days',
                      )}`}
                    />

                    <Space justify="space-between" align="start" size="large">
                      <DescriptionItem
                        isCurrency
                        label={t('amount_of_active_warranty')}
                        value={companyRate?.active_guarantee_amount}
                      />

                      <DescriptionItem
                        isCurrency
                        label={t('warranty_for_refund')}
                        value={companyRate?.total_refunded_guarantee}
                      />
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Loader>
          </Card.Body>
        </Card>
      </DebtorLayout>
    </Layout>
  );
};
