import * as React from 'react';
import { useSetState } from 'react-use';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import {
  Loader,
  Card,
  Space,
  Label,
  Button,
  Icon,
  Modal,
  Row,
  Col,
  AvatarInline,
  Alert,
  Badge,
  Textarea,
  Form,
  Input,
  DatePicker,
  useForm,
  Upload,
  useNotify,
  Checkbox,
} from 'ebs-design';
import { company, contracts, attachments } from 'api';
import { DescriptionItem, Layout, PDFViewer } from 'components';
import { UserContext } from 'contexts';
import { useFileFetch, usePermissions, useUpload } from 'hooks';
import { dateTimePickerFormat, formatDate, getAPIDateFormat } from 'libs';
import { defaultFilters, arrayContainsArray, extractResponseProps } from 'utils';
import { Permissions, Properties, CustomerRole, SystemRole, ContractUpload } from 'types';
import { Plus, Message, Download, Cross, FileUpload } from 'resources';

import { AdherentLayout } from '../AdherentLayout';
import { AdherentProfile } from '../AdherentProfile';

export const Contract: React.FC = () => {
  const { id } = useParams();
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const ref = React.useRef<HTMLDivElement>(null);
  const can = usePermissions(Permissions.ADHERENTS);
  const { userRoles } = React.useContext(UserContext);
  const [showModal, setShowModal] = React.useState(false);
  const [showProcure, setShowProcure] = React.useState(false);
  const [uploadFileId, setUploadFileId] = React.useState<number | null>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [visibleComments, setVisibleComments] = React.useState(false);
  const [visibleAddComment, setVisibleAddComment] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [PDFLoaded, setPDFLoaded] = React.useState(false);

  const [filters] = useSetState({ ...defaultFilters });
  const uploadProps = useUpload();

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', id],
    () => company.get(id),
    {
      enabled: Boolean(id),
    },
  );

  const { data: companyContract, isLoading: isCompanyContractLoading } = useQuery(
    ['company-contract', id],
    () => company.getContract(id),
    {
      enabled: Boolean(id),
    },
  );

  const { data: procureData } = useQuery(
    ['procureData', id],
    () => {
      if (companyContract?.id) {
        return contracts.getProcureData(companyContract.id);
      }
    },
    { enabled: Boolean(companyContract?.id) },
  );

  const { data: companyRate, isLoading } = useQuery(
    ['company-report-rates', id],
    () => company.getReportRates(id),
    {
      enabled: Boolean(id),
      select: (data) => data?.[0],
    },
  );

  const {
    data: dataComments,
    isLoading: isLoadingComments,
    refetch,
  } = useQuery(
    ['comments', filters],
    () => contracts.getComments({ id: companyContract?.id, ...filters }),
    { enabled: !!companyContract },
  );

  const addComment = useMutation(contracts.addComment, {
    onSuccess: () => {
      setVisibleAddComment(false);
      setComment('');
      refetch();
    },
  });

  const { mutate, isLoading: isLoadingSign } = useMutation(
    ({ id, redirect_url }: Properties) => attachments.getSign(id, redirect_url),
    {
      onSuccess: (response) => {
        window.open(response.redirect_url, '_self');
      },
    },
  );

  const postProcure = useMutation((data: ContractUpload) => contracts.postContract(data), {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },

    onSuccess: () => {
      queryClient.invalidateQueries(['company', id]);
      queryClient.invalidateQueries(['company-contract', id]);
      notify.success({ title: t('company'), description: t('success_data_save') });
      form.resetFields();
      onShowContractModal();
    },
  });

  const { mutate: mutateConfirmContract, isLoading: isLoadingMutateConfirm } = useMutation(
    (value: boolean) =>
      contracts.patchConfirmContract({ id: companyContract?.id, confirmed: value }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['company', id]);
        queryClient.invalidateQueries(['company-contract', id]);
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const { mutate: mutateApproveContract, isLoading: isLoadingMutateApprove } = useMutation(
    ({ id, value }: Properties) => contracts.patchApproveContract({ id, ...{ approved: value } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['company-contract', id]);
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const file = useFileFetch(companyContract?.attachment?.url);

  const fileProcure = useFileFetch(procureData?.procure?.attachment?.url);

  const canSign = React.useMemo(
    () =>
      !companyContract?.attachment?.signatures?.find((item) =>
        item.user.roles?.find((i) => i.name in SystemRole),
      ),
    [companyContract],
  );

  const onToggleHandler = React.useCallback(
    (value?) => setVisibleComments((i) => (typeof value === 'boolean' ? value : !i)),
    [],
  );

  const onToggleModal = () => setShowModal((i) => !i);
  const onShowModal = () => setShowProcure((i) => !i);
  const onShowContractModal = () => setVisible((v) => !v);

  const onLoaded = React.useCallback(() => setPDFLoaded(true), []);

  const commentsCount = React.useMemo(
    () => (dataComments ? dataComments.count : 0),
    [dataComments],
  );
  const commentsList = React.useMemo(
    () =>
      dataComments && dataComments.results.length > 0 ? (
        dataComments.results.map((comment) => (
          <Col size={12} key={comment.id}>
            <Card size="small">
              {comment.user && (
                <Card.Header>
                  <AvatarInline
                    alt={[comment.user.first_name, comment.user.last_name].join(' ')}
                    status="active"
                    description={comment.user.roles.map((role) => t(role.name)).join(', ')}
                    circle
                  />
                </Card.Header>
              )}
              <Card.Body>{comment.comment}</Card.Body>
            </Card>
          </Col>
        ))
      ) : (
        <Col size={12}>
          <Alert type="info" message={t('no_comments')} />
        </Col>
      ),
    [t, dataComments],
  );

  const onSave = React.useCallback(() => {
    addComment.mutate({
      id: dataCompany?.contract.id,
      comment,
    });
  }, [comment, dataCompany, addComment]);

  const onSignClick = React.useCallback(
    () => mutate({ id: companyContract?.attachment?.id, redirect_url: window.location.href }),
    [mutate, companyContract],
  );

  const onAddComment = (): void => {
    setVisibleAddComment(true);
    setVisibleComments(true);
  };

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const handleSubmit = (formData: Properties) => {
    if (uploadFileId && id)
      postProcure.mutate({
        number: formData.number,
        number_instance: { order: Number(formData.order) },
        company: id,
        signed_date: getAPIDateFormat(formData.signed_date),
        attachment: uploadFileId,
      });
  };

  const onSaveIdToStateAndOpenModal = (id) => {
    setUploadFileId(id);
    onShowContractModal();
  };

  return (
    <Layout>
      <AdherentLayout>
        <Loader loading={isLoadingCompany}>
          {dataCompany && <AdherentProfile data={dataCompany} />}
        </Loader>
        <Card>
          <Card.Header bordered>{t('factoring_contract')}</Card.Header>

          <Card.Body className="bg-white p-0">
            <Loader loading={isLoading || isLoadingCompany}>
              <Row className="overflow-hidden" g={0}>
                <Col className="p-20">
                  <DescriptionItem
                    label={t('status')}
                    value={t(companyRate?.invoice_status?.toLowerCase?.() || '---')}
                  />
                </Col>

                <Col className="p-20">
                  <DescriptionItem
                    label={t('nr_of_active_invoices')}
                    value={companyRate?.active_invoices}
                  />
                </Col>

                <Col className="p-20">
                  <DescriptionItem
                    label={t('date_signed')}
                    value={formatDate?.(dataCompany?.contract?.signed_date) || '---'}
                  />
                </Col>

                <Col className="p-20">
                  <DescriptionItem
                    label={t('available_to')}
                    value={formatDate?.(dataCompany?.contract?.expiring_date) || '---'}
                  />
                </Col>

                <Col style={{ maxWidth: 0 }}>
                  <div className="form-divider vertical" />
                </Col>

                <Col className="bg-grey p-20" lg={4}>
                  <Space justify="space-between" className="mb-12">
                    <DescriptionItem
                      label={t('contract_number')}
                      value={dataCompany?.contract?.number}
                    />

                    <Space justify="start" direction="vertical" size="small" align="end">
                      <Badge count={commentsCount} type="danger">
                        <Button
                          className="no-wrap"
                          prefix={<Icon type="eye" />}
                          disabled={!companyContract?.attachment || isCompanyContractLoading}
                          loading={isCompanyContractLoading}
                          onClick={onToggleModal}
                        >
                          {t('factoring_contract')}
                        </Button>
                      </Badge>
                      <Checkbox
                        onChange={(value) => mutateConfirmContract(value)}
                        disabled={
                          !companyContract?.attachment ||
                          isCompanyContractLoading ||
                          isLoadingMutateConfirm
                        }
                        text={t('confirmed')}
                        checked={companyContract?.attachment?.signatures?.length === 2}
                      />
                    </Space>
                  </Space>

                  <Space
                    justify="space-between"
                    className={cn('mb-12', {
                      hide_upload: companyContract && !!companyContract.attachment,
                    })}
                  >
                    <Space size="large" align="start" direction="vertical">
                      <Label text={<span className="fw-600  ">{t('upload_contract')}</span>} />
                    </Space>

                    <Upload
                      data={{
                        type: 'CONTRACT',
                      }}
                      {...uploadProps}
                      onSuccess={(e) => {
                        onSaveIdToStateAndOpenModal(e[0].id);
                      }}
                    >
                      <Button
                        className="no-wrap"
                        prefix={<Icon component={FileUpload} />}
                        type="ghost"
                        disabled={
                          (companyContract &&
                            companyContract?.signed &&
                            !!companyContract?.attachment?.signatures?.length &&
                            companyContract?.attachment?.signatures?.length >= 2) ||
                          isCompanyContractLoading
                        }
                      >
                        {t('upload')}
                      </Button>
                    </Upload>
                  </Space>

                  <Space justify="space-between">
                    <Space size="large" align="start" direction="vertical">
                      <Label text={<span className="fw-600  ">{t('procure')}</span>} />
                    </Space>
                    <Badge count={commentsCount} type="danger">
                      <Button
                        className="no-wrap"
                        prefix={<Icon type="eye" />}
                        disabled={
                          (companyContract && !companyContract.attachment) ||
                          isCompanyContractLoading ||
                          !procureData
                        }
                        buttonClass="procure"
                        loading={isCompanyContractLoading}
                        onClick={onShowModal}
                      >
                        {t('procure')}
                      </Button>
                    </Badge>
                  </Space>
                </Col>
              </Row>

              <div className="form-divider m-0" />

              <Row className="overflow-hidden" g={0}>
                <Col>
                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('available_limit')}
                        value={dataCompany?.limit?.accessible}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('total_nr_of_invoices')}
                        value={companyRate?.total_invoices}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('total_turnover')}
                        value={companyRate?.total_turnover}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('average_monthly_turnover')}
                        value={companyRate?.average_monthly_turnover}
                      />
                    </Col>
                  </Row>

                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t(`total${!isAdherent ? '_collected' : ''}_commission`)}
                        value={companyRate?.total_commission_paid}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t(`total${!isAdherent ? '_collected' : ''}_penalties`)}
                        value={companyRate?.total_penalties_paid}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        isCurrency
                        label={t('total_warranty_returned')}
                        value={companyRate?.total_refunded_guarantee}
                      />
                    </Col>

                    <Col size={12} sm={6} lg={3}>
                      <DescriptionItem
                        label={t('nr_of_invoices_under_examination')}
                        value={companyRate?.invoices_under_examination}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col style={{ maxWidth: 0 }}>
                  <div className="form-divider vertical" />
                </Col>

                <Col className="p-20" lg={4}>
                  <Space
                    align="start"
                    direction="vertical"
                    justify="space-between"
                    style={{ height: '100%' }}
                  >
                    <DescriptionItem
                      label={t('average_invoice')}
                      value={` ${t('amount')}: ${companyRate?.average_invoice_value || 0} MDL / ${t(
                        'term',
                      )}:
                        ${Math.round?.(Number(companyRate?.average_invoice_term)) || 0} ${t(
                        'days',
                      )}`}
                    />

                    <Space justify="space-between" align="start" size="large">
                      <DescriptionItem
                        isCurrency
                        label={t('amount_of_active_warranty')}
                        value={companyRate?.active_guarantee_amount}
                      />

                      <DescriptionItem
                        isCurrency
                        label={t('warranty_for_refund')}
                        value={companyRate?.total_refunded_guarantee}
                      />
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Loader>
          </Card.Body>
        </Card>

        <Modal
          open={showModal}
          size="large"
          header={t('factoring_contract')}
          onClose={onToggleModal}
        >
          <Modal.Content className="p-0">
            <Loader loading={isLoadingCompany || isLoadingComments || file.isLoading}>
              <Row className="justify-space-between overflow-hidden pc-comments__container" g={0}>
                <Col size={12} sm={visibleComments ? 7 : 12} className="transition-width">
                  <div ref={ref} style={{ height: '100%' }}>
                    <PDFViewer file={file.data!} style={{ marginBottom: -4 }} onLoad={onLoaded} />
                  </div>
                </Col>
                <Col size={12} sm={5} gy={3} gx={4} className="pc-comments transition-width">
                  {visibleAddComment && (
                    <>
                      <Textarea
                        value={comment}
                        onChange={setComment}
                        placeholder={t('add_comment')}
                      />
                      <Space justify="end" className="mb-20 mt-10">
                        <Button
                          type="ghost"
                          prefix={<Icon component={Cross} />}
                          onClick={() => setVisibleAddComment((i) => !i)}
                        >
                          {t('hide')}
                        </Button>
                        <Button
                          type="primary"
                          prefix={<Icon type="check" model="bold" />}
                          onClick={onSave}
                        >
                          {t('save')}
                        </Button>
                      </Space>
                    </>
                  )}
                  {visibleComments && !PDFLoaded ? (
                    <Loader loading={!PDFLoaded} />
                  ) : (
                    <Row gy={3}>{commentsList}</Row>
                  )}
                </Col>
              </Row>
            </Loader>
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Space>
                {can.perform.sign && (
                  <Badge text={!canSign ? t('signed') : undefined} type="success">
                    <Button
                      type="primary"
                      prefix={<Icon type="check" model="bold" />}
                      onClick={onSignClick}
                      loading={isLoadingSign}
                      disabled={!canSign}
                    >
                      {t(canSign ? 'sign_electronically' : 'signed')}
                    </Button>
                  </Badge>
                )}
                <Button
                  onClick={() => window.open(dataCompany?.contract?.attachment?.url, '_blank')}
                  prefix={<Icon component={Download} />}
                >
                  {t('download')}
                </Button>

                <Checkbox
                  onChange={(value) => mutateApproveContract({ id: companyContract?.id, value })}
                  disabled={
                    !companyContract?.attachment ||
                    isCompanyContractLoading ||
                    isLoadingMutateApprove
                  }
                  text={t('approved_contract')}
                  checked={companyContract?.approved}
                />
              </Space>

              <Space>
                {dataCompany?.contract && !visibleAddComment && (
                  <Button
                    type="primary"
                    prefix={<Icon component={Plus} />}
                    className="ml-10"
                    onClick={onAddComment}
                  >
                    {t('add_comment')}
                  </Button>
                )}
                {commentsCount || visibleComments ? (
                  <Badge text={commentsCount.toString()} type="danger">
                    <Button
                      type={visibleComments ? 'fill' : 'ghost'}
                      prefix={<Icon component={Message} />}
                      onClick={onToggleHandler}
                    >
                      {t(visibleComments ? 'hide' : 'see_comments')}
                    </Button>
                  </Badge>
                ) : null}
              </Space>
            </Space>
          </Modal.Footer>
        </Modal>

        <Modal
          open={visible}
          size="small"
          onClose={onShowContractModal}
          title={t('upload_contract')}
        >
          <Modal.Content className="pc-invite-user">
            <h3 className="mb-15">{t('enter_data_contract')}</h3>
            <Form
              form={form}
              type="horizontal"
              onFinish={handleSubmit}
              labelOptions={{ col: { size: 3 } }}
              controlOptions={{ col: { size: 8 } }}
            >
              <Form.Field name="number" label={t('contract_number')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>

              <Form.Field name="order" label={t('order')} rules={[{ required: true }]}>
                <Input type="number" />
              </Form.Field>

              <Form.Field name="signed_date" label={t('date_signed')} rules={[{ required: true }]}>
                <DatePicker showTimeSelect dateFormat={dateTimePickerFormat} />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={() => setVisible(false)}>{t('cancel')}</Button>

              <Button
                type="primary"
                prefix={<Icon type="check" model="bold" />}
                onClick={() => form.submit()}
              >
                {t('save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>

        <Modal open={showProcure} size="large" header={t('procure')} onClose={onShowModal}>
          <Modal.Content className="p-0">
            <Loader loading={isLoadingCompany || isLoadingComments || file.isLoading}>
              <Row className="justify-space-between overflow-hidden pc-comments__container" g={0}>
                <Col size={12} sm={12} className="transition-width">
                  <div ref={ref} style={{ height: '100%' }}>
                    <PDFViewer
                      file={fileProcure.data!}
                      style={{ marginBottom: -4 }}
                      onLoad={onLoaded}
                    />
                  </div>
                </Col>
              </Row>
            </Loader>
          </Modal.Content>
          <Modal.Footer>
            <Space justify="center">
              <Button
                onClick={() => window.open(dataCompany?.contract?.attachment?.url, '_blank')}
                prefix={<Icon component={Download} />}
              >
                {t('download')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      </AdherentLayout>
    </Layout>
  );
};
