import * as React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Space, Button, Row, Col, Icon, Loader, Card, Modal, Tabs } from 'ebs-design';
import { company } from 'api';
import { UserContext } from 'contexts';
import { UpdateArrow } from 'resources';
import { capitalize, arrayContainsArray } from 'utils';
import { DescriptionItem, HistoryTable } from 'components';
import { InvoiceStatus, HistoryModels, RequestType, CustomerRole } from 'types';

export const Description: React.FC = () => {
  const { t } = useIntl();
  const { user, userRoles } = React.useContext(UserContext);

  const [showHistory, setShowHistory] = React.useState(false);
  const [active, setActive] = React.useState(HistoryModels.REQUEST);

  const enabled = React.useMemo(() => !!user?.company, [user]);

  const { data, isLoading } = useQuery(
    ['company-report-rates', user!.company?.id],
    () => company.getReportRates(user!.company?.id, true),
    {
      enabled,
    },
  );

  const historyTabs = React.useMemo(
    () => [
      {
        label: <span>{t('limits')}</span>,
        key: HistoryModels.REQUEST,
        content: (
          <HistoryTable
            model={HistoryModels.REQUEST}
            columns={['limit']}
            query={{ old_company: user!.company?.id, old_type: RequestType.CARD }}
            excludeAdherents
          />
        ),
      },
      {
        label: <span>{t('rates')}</span>,
        key: HistoryModels.COMPANYRATE,
        content: (
          <HistoryTable
            model={HistoryModels.COMPANYRATE}
            columns={['commission', 'guarantee']}
            query={{ old_company: user!.company?.id }}
            excludeAdherents
          />
        ),
      },
    ],
    [t, user],
  );

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const totalInvoice = React.useMemo(
    () => data?.filter((invoice) => invoice?.invoice_status === InvoiceStatus?.TOTAL)?.[0],
    [data],
  );

  const totalRemained = React.useMemo(
    () => data?.filter((invoice) => invoice?.invoice_status === InvoiceStatus?.REMAINED)?.[0],
    [data],
  );

  return (
    <>
      <Card className="pc-limits-description mb-20">
        <Card.Header bordered>
          <Space align="center" justify="space-between">
            <h4>{t('limits_and_general_data')}</h4>

            <Button
              type="primary"
              prefix={<Icon component={UpdateArrow} />}
              onClick={() => setShowHistory((i) => !i)}
              disabled={showHistory}
            >
              {t('track_history')}
            </Button>
          </Space>
        </Card.Header>

        <Card.Body className="py-25 px-20">
          <Loader loading={isLoading}>
            <Row className="pc-limits-description__row--border-bottom mb-25 pb-10">
              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('approved_limit')}
                  value={totalInvoice?.approved_limit}
                />
              </Col>

              <Col size={3}>
                <DescriptionItem
                  isCurrency
                  label={t('available_limit')}
                  value={totalInvoice?.available_limit}
                />
              </Col>
            </Row>

            <Row className="pc-limits-description__row--border-bottom mb-25 pb-10">
              <Col size={2}>
                <DescriptionItem
                  label={t('total_nr_of_invoices')}
                  value={totalInvoice?.total_invoices}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('total_turnover')}
                  value={totalInvoice?.total_turnover}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('average_monthly_turnover')}
                  value={totalInvoice?.average_monthly_turnover}
                />
              </Col>

              <Col>
                <DescriptionItem
                  label={t('average_invoice')}
                  value={`${capitalize(t('amount'))}: ${
                    (enabled && totalInvoice?.average_invoice_value) || 0
                  } MDL / ${capitalize(t('term'))}: ${
                    (enabled && Math.round?.(Number(totalInvoice?.average_invoice_term))) || 0
                  } ${t('days')}`}
                />
              </Col>

              <Col>
                <DescriptionItem
                  isDays
                  label={t('max_nr_of_remaining_days')}
                  value={totalInvoice?.max_number_of_outstanding_days}
                />
              </Col>
            </Row>

            <Row className="pc-limits-description__row--border-bottom mb-25 pb-10">
              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t(`total${!isAdherent ? '_collected' : ''}_commission`)}
                  value={totalInvoice?.total_commission_paid}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t(`total${!isAdherent ? '_collected' : ''}_penalties`)}
                  value={totalInvoice?.total_penalties_paid}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('total_warranty_returned')}
                  value={totalInvoice?.total_refunded_guarantee}
                />
              </Col>
            </Row>

            <Row className="pc-limits-description__row--border-bottom mb-25 pb-10">
              <Col size={2}>
                <DescriptionItem
                  label={t('nr_of_active_invoices')}
                  value={totalInvoice?.active_invoices}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  label={t('nr_of_remaining_invoices')}
                  value={totalRemained?.total_invoices}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('amount_on_remaining_invoices')}
                  value={totalRemained?.total_turnover}
                />
              </Col>

              <Col>
                <DescriptionItem
                  isCurrency
                  label={t('amount_of_active_warranty')}
                  value={totalInvoice?.active_guarantee_amount}
                />
              </Col>
            </Row>

            <Row>
              <Col size={2}>
                <DescriptionItem
                  label={t('nr_of_invoices_under_examination')}
                  value={totalInvoice?.invoices_under_examination}
                />
              </Col>

              <Col size={2}>
                <DescriptionItem
                  isCurrency
                  label={t('amount_on_invoices_under_examination')}
                  value={totalInvoice?.total_turnover_under_examination}
                />
              </Col>
            </Row>
          </Loader>
        </Card.Body>
      </Card>

      <Modal
        open={showHistory}
        size="large"
        title={t('history')}
        onClose={() => setShowHistory(() => false)}
      >
        <Modal.Content className="p-0 pc-history-table">
          <Tabs activeTab={active} setActiveTab={(value) => setActive(value as HistoryModels)}>
            {[...historyTabs].map(({ key, ...item }) => (
              <Tabs.Tab key={key} tabKey={key} {...item} />
            ))}

            {historyTabs.map((item) => (
              <Tabs.Panel key={item.key} tabKey={item.key}>
                {item.content}
              </Tabs.Panel>
            ))}
          </Tabs>
        </Modal.Content>
      </Modal>
    </>
  );
};
