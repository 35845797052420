import React from 'react';
import { Label, Space } from 'ebs-design';
import { useIntl } from 'estafette-intl';

interface DescriptionItemProps {
  label: string;
  value?: number | string;
  isCurrency?: boolean;
  isDays?: boolean;
}

export const DescriptionItem = ({ label, value = 0, isCurrency, isDays }: DescriptionItemProps) => {
  const { t } = useIntl();

  const valueSuffix = (isCurrency && 'MDL') || (isDays && t('days'));

  return (
    <Space align="start" direction="vertical" size={2} className="mb-8">
      <Label text={label} className="description-item__label">
        {label}
      </Label>

      <div className="description-item__value">
        {value} {valueSuffix}
      </div>
    </Space>
  );
};
