import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import {
  Results,
  Properties,
  Invoice,
  User,
  FullCompany,
  Contract,
  CompanyRates,
  CompanyManagement,
  InvoiceStatus,
} from 'types';
import { transformResponse, formatNumber } from 'utils';

export const company = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`/companies/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(company.getList.name),
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'payed',
              'sold',
              'amount',
              'statistics.penalties.interest',
              'balance',
              'guarantee.returns.amount',
              'commission.amount',
              'guarantee.amount',
              'limit.current',
              'limit.accessible',
              'limit.inaccessible',
              'sum_of_active_invoices',
              'sum_of_remained_invoices',
            ],
            wrapper: (value) => formatNumber(value),
          }),
      },
    );

    return data;
  },

  get: async (id?: string | number) => {
    const { data } = await axios.get<FullCompany>(`/companies/${id}/`, {
      transformResponse: (response) =>
        transformResponse(response, {
          properties: ['limit.current', 'limit.accessible', 'limit.inaccessible'],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(company.get.name),
    });

    return data;
  },

  getUsers: async ({ queryKey }: Properties) => {
    const { companyId, ...params } = queryKey[1];
    const { data } = await axios.get<Results<User<string[]>>>(
      stringifyUrl(`/companies/${companyId}/users/list/`, params),
      {
        cancelToken: cancelToken(company.getUsers.name),
      },
    );

    return data;
  },

  getUsersInvitations: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<User<string[]>>>(
      stringifyUrl(`/companies/${queryKey[1]}/invites/list/`, queryKey[2]),
      {
        cancelToken: cancelToken(company.getUsersInvitations.name),
      },
    );

    return data;
  },

  inviteUser: async ({ id, ...props }: Properties) => {
    const { data } = await axios.post<User>(`/companies/${id}/invites/`, props, {
      cancelToken: cancelToken(company.inviteUser.name),
    });

    return data;
  },

  resendInvitation: async ({ companyId, id }: { companyId: number; id: number }) => {
    const { data } = await axios.get<Results<User<string[]>>>(
      stringifyUrl(`/companies/${companyId}/invites/${id}/resend/`),
      {
        cancelToken: cancelToken(company.getUsersInvitations.name),
      },
    );

    return data;
  },

  resendInvitationUsers: async (id: number) => {
    const { data } = await axios.get<Results<User<string[]>>>(
      stringifyUrl(`/users/invites/${id}/resend/`),
      {
        cancelToken: cancelToken(company.resendInvitationUsers.name),
      },
    );

    return data;
  },

  create: async (bodyData: Properties) => {
    return await axios.post(`/companies/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(company.create.name),
    });
  },

  update: async ({ companyId, data }: Properties) => {
    return await axios.patch(`/companies/${companyId}/`, data, {
      transformResponse,
      cancelToken: cancelToken(company.update.name),
    });
  },

  affiliates_company: async (params: Properties) => {
    const { data } = await axios.get<Results<FullCompany>>(
      `/reports/companies/${params.id}/affiliates/list/`,
      {
        params,
        cancelToken: cancelToken(company.affiliates_company.name),
      },
    );

    return data;
  },

  getInvoices: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Invoice>>(
      stringifyUrl(`/companies/${queryKey[1]}/invoices/list/`, queryKey[2]),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'payed',
              'sold',
              'amount',
              'statistics.penalties.interest',
              'balance',
              'guarantee.returns.amount',
              'commission.amount',
              'guarantee.amount',
            ],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(company.getInvoices.name),
      },
    );

    return data;
  },

  getUser: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<User>(`/companies/${queryKey[1]}/users/${queryKey[2]}/`, {
      transformResponse,
    });

    return data;
  },

  addUser: async (id: number, bodyData: Properties) => {
    return await axios.post(`/companies/${id}/users/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(company.addUser.name),
    });
  },

  updateUser: async (companyId: number, userId: number, bodyData: Properties) => {
    return await axios.put(`/companies/${companyId}/users/${userId}/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(company.updateUser.name),
    });
  },

  patchUser: async (companyId: number, userId: number, bodyData: Properties) => {
    return await axios.patch(`/companies/${companyId}/users/${userId}/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(company.patchUser.name),
    });
  },

  deleteUser: async ({ companyId, userId }: Properties) => {
    return await axios.delete(`/companies/${companyId}/users/${userId}/`, {
      cancelToken: cancelToken(company.deleteUser.name),
    });
  },

  getRates: async (id?: number, params: Properties = {}, order?: boolean) => {
    const { data } = await axios.get(`/companies/${id}/rates/list/`, {
      params,
      transformResponse: (response) =>
        transformResponse(response, {
          properties: ['percent', 'personalized.commission', 'personalized.guarantee'],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(company.getRates.name),
    });

    const resultsByCategory = data.results.reduce((acc, item) => {
      item.categories.forEach((category) => {
        if (acc[category.code_name]) {
          acc[category.code_name].push(item);
          return;
        }
        acc[category.code_name] = [category.code_name, item];
      });
      return acc;
    }, {});

    const resultsByCategoryOrdered = Object.keys(resultsByCategory)
      .sort()
      .map((key) => resultsByCategory[key]);

    return {
      ...data,
      results: order ? resultsByCategoryOrdered : data.results,
    };
  },

  updateRate: async (companyId: string, rateId: number, bodyData: Properties) => {
    return await axios.patch(`/companies/${companyId}/rates/${rateId}/`, bodyData, {
      transformResponse,
    });
  },

  getContract: async (id?: number, params?: Properties) => {
    const { data } = await axios.get<Contract>(`companies/${id}/contracts/`, {
      cancelToken: cancelToken(company.getContract.name),
      params,
    });

    return data;
  },

  generateAdditionalAct: async ({ id, ...bodyData }: Properties) => {
    const { data } = await axios.put(`/companies/${id}/invoices/finances/`, bodyData, {
      cancelToken: cancelToken(company.generateAdditionalAct.name),
    });

    return data;
  },

  getReportRates: async (id, all?: boolean) => {
    const { data } = await axios.get<CompanyRates[]>(`/companies/${id}/reports/rates/`, {
      transformResponse: (response) =>
        transformResponse(response, {
          properties: [
            'approved_limit',
            'available_limit',
            'average_monthly_turnover',
            'average_invoice_value',
            'total_commission_paid',
            'total_penalties_paid',
            'total_refunded_guarantee',
            'total_turnover',
            'active_guarantee_amount',
            'total_turnover_under_examination',
          ],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(company.getReportRates.name),
    });

    return all ? data : data.filter((item) => item.invoice_status === InvoiceStatus.TOTAL);
  },

  getDebtorsList: async ({ queryKey }: Properties) => {
    const { companyId, ...filters } = queryKey[1];
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`/companies/${companyId}/debtors/list/`, filters),
      {
        cancelToken: cancelToken(company.getDebtorsList.name),
      },
    );

    return data;
  },

  updateCompanyCategory: async (id: number, bodyData: Properties) => {
    return await axios.patch(`/companies/${id}/edit/`, bodyData, {
      cancelToken: cancelToken(company.updateCompanyCategory.name),
    });
  },

  setCompanyCategoryNewRates: async (id: number) => {
    const { data } = await axios.get(`/companies/${id}/set-new-rates/`, {
      cancelToken: cancelToken(company.setCompanyCategoryNewRates.name),
    });
    return data;
  },

  getCompanyFutureRatesById: async (id?: number, params?: Properties) => {
    const { data } = await axios.get(`/companies/${id}/future/rates/list/`, {
      cancelToken: cancelToken(company.getCompanyFutureRatesById.name),
      params,
    });

    return data;
  },

  getCompanyHistoryRatesById: async (id?: number, params?: Properties) => {
    const { data } = await axios.get(`/companies/${id}/rates/logs/`, {
      cancelToken: cancelToken(company.getCompanyHistoryRatesById.name),
      params,
    });

    return data;
  },

  getCompanyHistoryDatesList: async (id?: number, params?: Properties) => {
    const { data } = await axios.get<Properties[]>(`/companies/${id}/companies/rates/logs/dates/`, {
      cancelToken: cancelToken(company.getCompanyHistoryDatesList.name),
      params,
    });
    return data;
  },

  createCompanyFutureRate: async (body: Properties) => {
    return await axios.post<Properties>('/companies/future/logs/rates/', body, {
      transformResponse,
    });
  },

  setCompanyFutureRates: async (id?: number, bodyData?: Properties) => {
    const { data } = await axios.post(`/companies/${id}/rates/set/`, bodyData, {
      cancelToken: cancelToken(company.setCompanyFutureRates.name),
    });
    return data;
  },

  getManagementList: async (companyId?: number, params?: Properties) => {
    const { data } = await axios.get<Results<CompanyManagement>>(
      `/companies/${companyId}/management/`,
      {
        cancelToken: cancelToken(company.getManagementList.name),
        params,
      },
    );
    return data;
  },

  getManagementById: async (companyId?: number, id?: number, params?: Properties) => {
    const { data } = await axios.get<CompanyManagement>(
      `/companies/${companyId}/management/${id}/`,
      {
        cancelToken: cancelToken(company.getManagementById.name),
        params,
      },
    );
    return data;
  },

  updateManagement: async (companyId?: number, id?: number, bodyData?: Properties) => {
    const { data } = await axios.patch(`/companies/${companyId}/management/${id}/`, bodyData, {
      cancelToken: cancelToken(company.updateManagement.name),
    });
    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(company);
