import { FactoringEntity, Unit } from 'types/common';
import { User } from 'types/users';
import { FactoringType } from './invoices';
import { AttachmentType } from './attachment';

export interface NomenclatureEntity {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  code_name: null | string;
  title: string;
  description: null | string;
}

export interface LocationEntity extends NomenclatureEntity {
  name: string;
  prefix: string;
  short_name: string;
  type: number;
}

export interface FactoringRange {
  id: number;
  minimum: number;
  maximum: number;
  currency: Currency;
  currency_id: number;
}

export interface RoleEntity {
  id: number;
  name: string;
}

export interface RatesEntity<T = number | null> extends NomenclatureEntity {
  description: null;
  code_name: null;
  period: Unit;
  minimum: T;
  maximum: T;
  minimum_operator: '>=' | '>' | '<=' | '<' | '=' | '-' | null;
  maximum_operator: '>=' | '>' | '<=' | '<' | '=' | '-' | null;
  formulae: any;
  percent: number;
  value: number;
  default: boolean;
  factoring_type: FactoringEntity;
  categories: number[];
}

export interface Currency {
  id: number;
  timestamp: null | string;
  edited_timestamp: null | string;
  code_name: null | string;
  title: null | string;
  description: null | string;
}

export interface Address {
  id: number;
  postal_code: string | null;
  subregion: null | string;
  type: 'LEGAL' | 'POSTAL';
  user: User;
  user_id: number;
  region: {
    id: number;
    name: null | string;
  };
  region_id: number;
  city: {
    id: number;
    name: string | null;
  };
  city_id: number;
  country: Country | null;
  country_id: string | null;
  district: District;
  district_id: number;
  location: Location;
  location_id: number;
  text: string | null;
  title: string | null;
}

export interface Attachments {
  id: number;
  file_name: string | null;
  name: string | null;
  mime_type: string;
  url: string | null;
  type: AttachmentType;
  width: string | null;
  height: string | null;
  signed: boolean;
}

export interface District {
  id: number;
  timestamp: null | string;
  edited_timestamp: null | string;
  code_name: null | string;
  title: null | string;
  name: null | string;
  short_name: null | string;
  prefix: null | string;
  description: null | string;
  type: number;
}

export interface Country {
  id: number;
  name: string;
  phone: string;
}

export interface Location {
  id: number;
  timestamp: null | string;
  edited_timestamp: null | string;
  code_name: null | string;
  title: null | string;
  name: null | string;
  short_name: null | string;
  prefix: null | string;
  description: null | string;
  type: number;
  district: number;
}

export enum ANNEX {
  ANNEX_1 = 'ANNEX_1',
  ANNEX_2 = 'ANNEX_2',
  ANNEX_3 = 'ANNEX_3',
  ANNEX_4 = 'ANNEX_4',
  ANNEX_8 = 'ANNEX_8',
}

export interface Indicator {
  row: number;
  source: ANNEX;
}

export interface Classifier extends NomenclatureEntity {
  categories: ClassifierCategory[];
  period: Unit;
  minimum: string | null;
  maximum: string | null;
  minimum_operator: string | null;
  maximum_operator: string | null;
  indicators: Indicator[] | null;
  currency: Currency;
  value: null;
  formulae: string;
  formula: string;
  score: number;
  user: null;
}

export type Category = NomenclatureEntity;

export interface ClassifierCategory extends Category {
  period: string;
  minimum: string | null;
  maximum: string | null;
  minimum_operator: string | null;
  maximum_operator: string | null;
  currency: Currency;
  classifier_id: number;
  category_id: number;
}

export interface ClassifierEntity {
  category: string;
  evaluate: number;
  formula: string;
  id: number;
  score: string;
  significant: boolean;
  title: string;
  value: number;
  classifier?: Classifier;
}

export type CodeName = 'A' | 'B' | 'C';

interface CategoryFactoringType {
  id: number;
  type: FactoringType;
  code_name: FactoringType;
  title: null | string;
}

interface CategoryRates {
  id: number;
  percent: string;
  guarantee: string;
  gc_percent: string;
  value: number;
  period: string;
}

interface CategoryRatesHistory extends Omit<CategoryRates, 'id' | 'guarantee'> {
  user_change: string;
}

export interface CategoryInfo {
  id: number;
  code_name: CodeName;
}

export interface CategoryRatesList {
  factoring_type: CategoryFactoringType;
  categories: {
    category_info: CategoryInfo;
    rates: CategoryRates[];
  }[];
  timestamp?: string;
}

export interface HistoryRatesData {
  category_info: CodeName;
  rates: CategoryRatesHistory[];
}

export interface CategoryHistoryRatesList {
  data: {
    NO_REGRESSION?: HistoryRatesData[];
    REGRESSION?: HistoryRatesData[];
  };
  timestamp: string;
}
